// @flow
import React from 'react';
import block from 'bem-cn';
import './PreLoader.scss';
import { BounceLoader } from 'react-spinners';

export type PreLoaderPropTypes = {
  fullscreen?: boolean,
  caption?: string,
};

const PreLoader = (props: PreLoaderPropTypes) => {
  const b = block('pre-loader');
  const { caption } = props;

  return <div className={b()}>
    <div className={b('spinner')}>
      <BounceLoader
        color="#fff"
      />
    </div>
    {caption && <div className={b('caption')}>
      {caption}
    </div>}
  </div>;
};

export default PreLoader;
