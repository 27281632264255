// @flow
import React from 'react';
import vkConnect, { UserInfo } from '@vkontakte/vk-connect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as vkActions from '../reducers/vk';
import Scenes from '../scenes';

export type AppPropTypes = {
  navigate: (location: string) => void,
  init: (userInfo: UserInfo) => void,
  register: (phoneInfo: any) => void,
  vk: vkState,
  rating: ratingState,
};

class App extends React.Component<AppPropTypes> {
  constructor(props) {
    super(props);
    const { init } = this.props;
    vkConnect.send('VKWebAppSetViewSettings', {
      status_bar_style: 'light',
      action_bar_color: 'none',
    });
    vkConnect.sendPromise('VKWebAppInit', {});
    vkConnect.sendPromise('VKWebAppGetUserInfo')
      .then(init);
    vkConnect.subscribe(({detail}) => {
      switch (detail.type) {
      default:
        break;
      }
    });
  }
  render() {
    const { vk, rating } = this.props;
    const { location } = vk;
    return <Scenes location={location} vk={vk} rating={rating} />;
  }
};

const mapDispatchToProps = dispatch => bindActionCreators(vkActions, dispatch);

const mapStateToProps = ({ vk, rating }) => ({ vk, rating });

export default connect(mapStateToProps, mapDispatchToProps)(App);
