// @flow
import axios, { AxiosInstance } from 'axios';
import { UserInfo } from '@vkontakte/vk-connect';

class API {
  client: AxiosInstance;


  client() {
    return axios.create({
      baseURL: '/api/v1',
      headers: {
        authorization: localStorage.getItem('phone'),
      },
    });
  }

  async rating(): Promise<Array<ContestParticipant>> {
    return (await this.client().get('/rating')).data;
  }

  register(userInfo: UserInfo) {
    return this.client().post('/user', userInfo);
  }

  async currentUser(): Promise<ContestParticipant> {
    return (await this.client().get('/user')).data;
  }
};

export default new API();
