// @flow
import React, { useState } from 'react';
import block from 'bem-cn';
import ContentLoader from 'react-content-loader';
import 'intersection-observer'; // optional polyfill
import Observer from '@researchgate/react-intersection-observer';
import {
  Section,
} from '../';
import { formatNumber } from '../../utils/format';
import './Rating.scss';

export type RatingPropTypes = {
  participants: Array<ContestParticipant>,
  vk: vkState,
};

const Rating = (props: RatingPropTypes) => {
  const b = block('rating');
  const { participants, vk } = props;
  const { isRegistered } = vk;
  const pageSize = 10;
  const [items, setItems] = useState(pageSize);

  return <Section title="Рейтинг участников">
    <div className={b()}>
      <p>
        Получайте балл за&nbsp;каждого друга, который воспользовался
        вашим промокодом в&nbsp;приложении Атласа, и&nbsp;совершил по&nbsp;нему
        свою первую поездку.
      </p>
      {!isRegistered && <p>
        Зарегистрируйтесь, чтобы учавствовать в рейтинге
        и получать баллы.
      </p>}
      {!participants && <ContentLoader
        width={window.innerWidth - 40}
        primaryColor="rgba(255,255,255, 0.25)"
        secondaryColor="rgba(255,255,255, 0.4)"
      >
        <rect
          x="0" y="0" rx="4" ry="4"
          width={window.innerWidth - 40} height="60"
        />
        <rect
          x="0" y="70" rx="4" ry="4"
          width={window.innerWidth - 40} height="60"
        />
      </ContentLoader>}
      {participants && participants.slice(0, items).map((p, i) => (
        <RatingItem
          key={p.userId}
          participant={p}
          position={i}
        />
      ))}
      {participants && items < participants.length &&
        <Observer
          onChange={({
            isIntersecting,
          }) => isIntersecting && setItems(items + pageSize)}
        >
          <span>Загрузить ещё...</span>
        </Observer>
      }
    </div>
  </Section>;
};

export type RatingItemPropTypes = {
  participant: ContestParticipant,
  position: number
};

const RatingItem = (props: RatingItemPropTypes) => {
  const b = block('rating-item');
  const { participant, position } = props;

  return <div className={b()}>
    <div className={b('avatar', { [position]: true })}>
      <img src={participant.avatarUrl} alt="Аватар" />
    </div>
    <div className={b('body')}>
      <div className={b('body-name')}>
        {participant.firstName} {participant.lastName}
      </div>
      <div className={b('body-city')}>
        {participant.city}
      </div>
    </div>
    <div className={b('score')}>
      <div className={b('score-value')}>{participant.promocodeTrips}</div>
      <div className={b('score-caption')}>
        {formatNumber(
          participant.promocodeTrips,
          'баллов', 'балл', 'балла'
        )}
      </div>
    </div>
  </div>;
};

export default Rating;
