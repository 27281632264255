// @flow

const formatNumber = (
  number: number,
  genPlural: string,
  accSingular: string,
  genSingular: string,
) => {
  if (number >= 10 && number <= 20) {
    return genPlural;
  }
  if ([0, 5, 6, 7, 8, 9].includes(number % 10)) {
    return genPlural;
  }
  if (number % 10 === 1) {
    return accSingular;
  }
  return genSingular;
};

export {
  formatNumber,
};
