// @flow
import React from 'react';
import { connect } from 'react-redux';
import SignUp from '../scenes/SignUp';
import { register } from '../reducers/vk';
import type { SignUpPropTypes } from '../scenes/SignUp';

class SignUpModule extends React.Component<SignUpPropTypes> {
  render() {
    return <SignUp {...this.props} />;
  };
}

const mapStateToProps = (state: { vk: vkState }) => ({
  userInfo: state.vk.userInfo,
  vk: state.vk,
});

const mapDispatchToProps = dispatch => ({
  register: userInfo => dispatch(register(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModule);
