/* eslint-disable react/jsx-no-target-blank */
// @flow
import React from 'react';
import type {Node} from 'react';
import block from 'bem-cn';
import { UserInfo } from '@vkontakte/vk-connect';
import {
  Link, Button,
  PreLoader,
} from '../../components';
import './SignUp.scss';

type StepType = {
  num: number,
  content: string | Node,
};

const steps: Array<StepType> = [
  {
    num: 1,
    content: <div>
      Ознакомьтесь с <Link location="rules">правилами
      проведения конкурса</Link>.
    </div>,
  },
  {
    num: 2,
    content: <div>
      Зарегистрируйтесь на сайте <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://atlasbus.ru"
      >
        atlasbus.ru
      </a> или <a href="https://atlasbus.by" target="_blank">atlasbus.by</a>,
      используя номер телефона, привязанный к вашему аккаунту ВКонтакте.
    </div>,
  },
  {
    num: 3,
    content: <div>
      Нажмите большую кнопку внизу этой страницы.
    </div>,
  },
];

export type SignUpPropTypes = {
  register: (userInfo: UserInfo) => void,
  userInfo: UserInfo,
  vk: vkState,
};

const SignUp = (props: SignUpPropTypes) => {
  const b = block('signup');
  const { register, userInfo, vk } = props;

  return <div className={b('signup')}>
    {vk.registration.processing && <PreLoader />}
    <h1>Принять участие в конкурсе</h1>
    <div className={b('instructions')}>
      {steps.map(s => (
        <SignUpStep step={s} key={s.num} />
      ))}
    </div>
    {vk.registration.error && <div className={b('error')}>
      <p><strong>Не получилось :(</strong></p>
      {vk.registration.error.code === 404 && <p>
        Номер телефона {vk.phone && vk.phone.phone_number} не
        зарегистрирован в Атласе. Пожалуйста, зарегистрируйтесь
        на <a href="https://atlasbus.ru" target="_blank">atlasbus.ru</a>.
      </p>}
      {vk.registration.error.code === 400 && <div>
        <p>
          К сожалению, к вашему аккаунту ВКонтакте не привязан номер телефона.
        </p>
        <p>
          Пожалуйста, <a href={
            (window.navigator.userAgent.indexOf('iPhone') > -1 ||
              window.navigator.userAgent.indexOf('Android') > -1) ?
              'https://m.vk.com/settings' : 'https://vk.com/settings'
          } target="_blank">
            привяжите номер телефона в настройках
          </a>, и попробуйте ещё раз
        </p>
      </div>}
      {vk.registration.error &&
        ![404, 400].includes(vk.registration.error.code) && <p>
          Произошла неизвестная ошибка, и нам не удалось зарегистрировать вас.
          Пожалуйста, напишите об этом на
        {' '}<a href="mailto:support@atlasbus.ru">support@atlasbus.ru</a>.
      </p>
      }
    </div>}
    <div className={b('cta')}>
      <Button
        size="large"
        onClick={ () => vk.isVkApp ?
          register(userInfo) :
          window.location = 'https://vk.com/app7166371_6326132#signup'
        }
      >
        Зарегистрироваться
      </Button>
    </div>
  </div>;
};

const SignUpStep = (props: {step: StepType}) => {
  const b = block('signup-step');
  const { step } = props;
  return <div className={b()}>
    <div className={b('num')}>
      <span>{step.num}</span>
    </div>
    <div className={b('content')}>
      {step.content}
    </div>
  </div>;
};

export default SignUp;
