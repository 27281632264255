// @flow
import React from 'react';
import type { Node } from 'react';
import block from 'bem-cn';
import vkConnect from '@vkontakte/vk-connect';
import { connect } from 'react-redux';
import { navigate } from '../../reducers/vk';
import './Link.scss';

export type LinkPropTypes = any & {
  location: string,
  children: Node,
  navigate: (location: string) => void,
  onClick?: () => void
};

const Link = (props: LinkPropTypes) => {
  const b = block('link');
  const {
    children, location,
    navigate, style, className,
    onClick,
    ...otherProps
  } = props;
  return <span className={[className, b()].join(' ')} style={{
    fontWeight: 500,
    textDecoration: 'underline',
    ...style,
  }} {...otherProps} onClick={() => {
    if (onClick) onClick();
    vkConnect.send('VKWebAppSetLocation', {
      location,
    });
    navigate(location);
  }}>
    {children}
  </span>;
};

const mapDispatchToProps = dispatch => ({
  navigate: location => dispatch(navigate(location)),
});

export default connect(null, mapDispatchToProps)(Link);
