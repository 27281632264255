// @flow
import React from 'react';
import type { Node } from 'react';
import block from 'bem-cn';
import { HR } from '../';
import './Section.scss';

export type SectionPropTypes = {
  title: string | Node,
  children?: Node
};

const Section = (props: SectionPropTypes) => {
  const { title, children } = props;
  const b = block('section');
  return <div className={b()}>
    <h2 className={b('title')}>{title}</h2>
    <HR className={b('hr')} />
    <div className={b('body')}>
      {children}
    </div>
  </div>;
};

export default Section;
