// @flow
import React from 'react';
import {
  Personal,
} from '../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../reducers/rating';

export type PersonalPropTypes = {
  vk: vkState,
  rating: ratingState,
  loadCurrentUser: () => void,
};

class PersonalModule extends React.Component<PersonalPropTypes> {
  componentDidMount() {
    this.props.loadCurrentUser();
  }
  render() {
    const {vk, rating} = this.props;
    return <Personal
      vk={vk}
      rating={rating}
    />;
  };
}

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);
const mapStateToProps = (state: {vk: vkState, rating: ratingState}) => ({
  vk: state.vk,
  rating: state.rating,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalModule);
