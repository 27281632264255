// @flow
import React from 'react';
import block from 'bem-cn';
import {
  Promo,
} from '../../components';
import './AnonPromo.scss';

export type AnonPromoPropTypes = {
  vk: vkState,
};

const AnonPromo = (props: AnonPromoPropTypes) => {
  const b = block('anon-promo');
  const { vk } = props;

  return <div className={b()}>
    <Promo vk={vk} />
  </div>;
};

export default AnonPromo;
