// @flow
import React from 'react';
import type { Node } from 'react';
import block from 'bem-cn';
import logo from '../img/logo.svg';
import Rules from './Rules';
import AnonPromo from './AnonPromo';
import SignUp from '../modules/SignUp';
import {
  Button,
  Link,
  Menu,
} from '../components';
import './index.scss';

export type ScenesPropTypes = {
  location: string,
  vk: vkState,
  rating: ratingState
};

const renderScene = (vk: vkState, rating: ratingState): Node => {
  const { location } = vk;
  const { currentUser } = rating;
  switch (location) {
  case '':
    return <AnonPromo vk={vk} />;
  case 'rules':
    return <Rules promocode={currentUser && currentUser.promocode} />;
  case 'signup':
    return <SignUp />;
  default:
    return <span>Nothing</span>;
  }
};

function Scenes(props: ScenesPropTypes) {
  const { location, vk, rating } = props;
  const b = block('App');

  return (
    <div className={b()}>
      <div className={b('header')}>
        <Menu />
        <img src={logo} alt="Лого" />
      </div>
      <div className={b('body')} style={{
        paddingBottom: vk.isRegistered ? 10 : undefined,
      }}>
        {renderScene(vk, rating)}
        {!vk.isRegistered &&
          location !== 'signup' &&
          (
            <Link location="signup" className={b('cta')} style={{
              textDecoration: 'none',
            }}>
              <Button size="large">
                Принять участие
              </Button>
            </Link>
          )
        }
      </div>
    </div>
  );
}

export default Scenes;
