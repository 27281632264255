// @flow
import {
  createAction,
  handleActions,
} from 'redux-actions';
import api from '../../utils/api';

const initialState: ratingState = {
};

const [ratingLoadStart, ratingLoadFinish] = [
  createAction('RATING_LOAD_START'),
  createAction('RATING_LOAD_FINISH'),
];

const loadRating = () => (dispatch: any) => {
  dispatch(ratingLoadStart());
  api.rating()
    .then(v => dispatch(ratingLoadFinish(
      v.sort((a, b) => b.promocodeTrips - a.promocodeTrips)
    )))
    .catch(e => dispatch(ratingLoadFinish(e)));
};

const [userLoadStart, userLoadFinish] = [
  createAction('USER_LOAD_START'),
  createAction('USER_LOAD_FINISH'),
];

const loadCurrentUser = () => (dispatch: any) => {
  dispatch(userLoadStart());
  api.currentUser()
    .then(user => dispatch(userLoadFinish(user)))
    .catch(error => dispatch(userLoadFinish(error)));
};

const reducer = handleActions(
  {
    [userLoadFinish]: (
      state: ratingState,
      { error, payload }: any
    ): ratingState => ({
      ...state,
      currentUser: error ? undefined : payload,
      currentUserLoadError: error ? 'Ошибка' : undefined,
    }),
    [ratingLoadFinish]: (
      state: ratingState,
      { error, payload }: any
    ): ratingState => {
      if (error) {
        return {
          ...state,
          error: payload.message,
        };
      }
      return {
        ...state,
        error: null,
        participants: payload,
      };
    },
  },
  initialState,
);

export {
  loadRating,
  loadCurrentUser,
};

export default reducer;

