// @flow
import React from 'react';
import block from 'bem-cn';
import ContentLoader from 'react-content-loader';
import connect from '@vkontakte/vk-connect';
import {
  Section,
  Button,
} from '../';
import {
  formatNumber,
} from '../../utils/format';
import './Personal.scss';

export type PersonalPropTypes = {
  vk: vkState,
  rating: ratingState,
};

const Personal = (props: PersonalPropTypes) => {
  const b = block('personal');
  const { rating } = props;
  const { currentUser } = rating;
  const ratingItem = currentUser && rating.participants &&
    rating.participants
      .map((p, i) => ({...p, position: i + 1}))
      .filter(p => p.userId === currentUser.userId)[0];

  return <Section title="Мой профиль" className={b()}>
    {currentUser && ratingItem && <div>
      {ratingItem && <p>
        <strong>{ratingItem.position}</strong> место в рейтинге.
        {ratingItem.promocodeUsed > 0 ?
          ` Промокод активировали ${ratingItem.promocodeUsed} раз` :
          ' Вашим промокодом пока ни разу не воспользовались.'
        }
        {ratingItem.promocodeUsed > 0 && (
          ratingItem.promocodeTrips > 0 ?
            ` и совершили ${ratingItem.promocodeTrips} ${
              formatNumber(
                ratingItem.promocodeTrips,
                'поездок', 'поездку', 'поездки'
              )
            }.` :
            ', но пока не совершили ни одной поездки.'
        )}
      </p>}
      {currentUser && <div className={b('promocode')}>
        <div className={b('promocode-value')}>
          <div className={b('promocode-value-title')}>
            {currentUser.promocode}
          </div>
          <div className={b('promocode-value-caption')}>
            Промокод
          </div>
        </div>
        <div className={b('promocode-share')}>
          <Button
            onClick={() => {
              connect.send('VKWebAppShowWallPostBox', {
                message: `Билеты на автобусы в приложении Атласа!\n\n` +
                  `Дарю промокод на 20% кешбек на первый билет ` +
                  `в приложении: ${currentUser.promocode}.`,
                attachments: `https://atlasbus.ru/share/app/${currentUser.promocode}/`,
              });
            }}
          >
            Поделиться
          </Button>
        </div>
      </div>}
    </div>}
    {(!currentUser || !ratingItem) &&
      !rating.currentUserLoadError &&
      <ContentLoader
        height={120}
        width={window.innerWidth - 40}
        speed={2}
        primaryColor="rgba(255,255,255, 0.25)"
        secondaryColor="rgba(255,255,255, 0.4)"
      >
        <rect
          x="0" y="0" rx="4" ry="4"
          width={window.innerWidth - 60} height="12"
        />
        <rect
          x="0" y="20" rx="4" ry="4"
          width={window.innerWidth - 80} height="12"
        />
        <rect
          x="0" y="40" rx="4" ry="4"
          width={window.innerWidth - 60} height="70"
        />
      </ContentLoader>
    }
  </Section>;
};

export default Personal;
