import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'whatwg-fetch';
import './index.scss';
import store from './store';
import App from './modules/App';
import * as serviceWorker from './serviceWorker';

const Router = (props) => {
  switch (document.location.pathname) {
  case '/rules':
    document.location = 'https://docs.google.com/document/d/1p16JrUX7xKqw8k7uNqywi_81QVikutRs_jsnFsr8OdU/export?format=pdf';
    return <div>
      <a href="https://docs.google.com/document/d/1p16JrUX7xKqw8k7uNqywi_81QVikutRs_jsnFsr8OdU/export?format=pdf">Выполняется перенаправление на документ с правилами</a>
    </div>;
  default:
    return <App {...props} />;
  };
};

ReactDOM.render(<Provider store={store}>
  <Router />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
