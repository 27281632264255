// @flow
import {
  createActions,
  handleActions,
  createAction,
  // combineActions
} from 'redux-actions';
import connect, { UserInfo } from '@vkontakte/vk-connect';
import api from '../../utils/api';

const defInitialLocation = document.location.hash.split('#')[1] || '';
const phone = (() => {
  const localPhone = localStorage.getItem('phone');
  if (!localPhone) {
    return undefined;
  };
  try {
    return JSON.parse(atob(localPhone));
  } catch (e) {
    console.error(e);
    return undefined;
  }
})();

const defaultState: vkState = {
  isVkApp: false,
  location: defInitialLocation,
  history: [],
  registration: {
    processing: false,
  },
  phone,
  isRegistered: !!phone,
};


const {
  navigate,
  init,
  navigateBack,
} = createActions({
  NAVIGATE: (location = '') => ({ location }),
  NAVIGATE_BACK: () => {},
  INIT: (info: UserInfo) => info,
});

const [
  startRegister,
  finishRegister,
] = [
  createAction('START_REGISTER'),
  createAction('FINISH_REGISTER'),
];

const register = (
  vkUserInfo: UserInfo
) => async (dispatch: (...any) => void) => {
  const userPhone = await connect.sendPromise('VKWebAppGetPhoneNumber');
  console.log({userPhone});
  dispatch(startRegister(userPhone));
  api.register(vkUserInfo)
    .then(result => dispatch(finishRegister(result.data)))
    .catch(error => dispatch(finishRegister(error)));
};

const vkReducer = handleActions(
  {
    [navigate]: (state, {payload: { location }}): vkState => ({
      ...state,
      location,
      history: [...state.history, location],
    }),
    [navigateBack]: (state: vkState): vkState => {
      const newHistory = [...state.history];
      newHistory.pop();
      return {
        ...state,
        history: newHistory,
      };
    },
    [startRegister]: (state: vkState, {payload}: any): vkState => {
      const data = btoa(JSON.stringify({
        ...payload,
        vkUserId: state.userInfo ? state.userInfo.id : null,
      }));
      document.cookie = `__session=${data}`;
      localStorage.setItem('phone', data);

      return {
        ...state,
        phone: payload,
        registration: {
          processing: true,
        },
      };
    },
    [finishRegister]: (
      state: vkState,
      { error, payload }: any,
    ): vkState => {
      if (error || payload.error) {
        localStorage.removeItem('phone');
        return {
          ...state,
          registration: {
            processing: false,
            error: error || payload.error,
          },
        };
      }
      return {
        ...state,
        registration: {
          processing: false,
          error,
        },
        isRegistered: true,
        location: error ? state.location : '',
        history: error ? state.history : [],
      };
    },
    [init]: (
      state: vkState,
      { payload }: {payload: UserInfo}
    ): vkState => {
      return {
        ...state,
        isVkApp: true,
        userInfo: payload,
      };
    },
  },
  defaultState
);

export { navigate, init, navigateBack, register };

export default vkReducer;
