// @flow
import React from 'react';
import block from 'bem-cn';
import './Pagination.scss';

export type PaginationPropsType = {
  count: number,
  index: number,
  onClick?: (index: number) => void,
};

const Pagination = (props: PaginationPropsType) => {
  const {index, count, onClick} = props;
  const b = block('pagination');

  return <div className={b()}>
    {[...Array(count).keys()].map(i => <div
      onClick={() => onClick && onClick(i)}
      className={b('dot', {
        active: i === index,
      })} key={i}
    />)}
  </div>;
};

export default Pagination;
