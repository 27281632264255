// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import block from 'bem-cn';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {
  Pagination,
  Section,
} from '../';
import a3 from './a3.png';
import marshall from './marshall.png';
import bank from './bank.png';
import './Presents.scss';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export type PresentItemType = {
  image: string,
  title: string,
  description: string | Node,
};

export type PresntsPropTypes = {
  showDescription?: Boolean,
  customDescription?: any,
  customTitle?: string,
};

const presents: Array<PresentItemType> = [
  {
    image: a3,
    title: 'Первое место',
    description: <span>
      Мобильный телефон Xiaomi Mi A3 32Gb
    </span>,
  },

  {
    image: marshall,
    title: 'Второе место',
    description: <span>
      Bluetooth наушники Marshall Major III
    </span>,
  },

  {
    image: bank,
    title: 'Третье место',
    description: <span>
      Аккумулятор Xiaomi Mi Power Bank 3 20000
    </span>,
  },
];

const Presents = (props: PresntsPropTypes) => {
  const b = block('presents');
  const [index, setIndex] = useState(0);
  const {
    showDescription = false,
    customDescription,
    customTitle,
  } = props;

  return <Section title={customTitle || 'Призы'}>
    <div className={b()}>
      {showDescription && <p>
        Тем, кто зарегистрируется в&nbsp;акции
        и&nbsp;пригласит больше всех пассажиров
        достанутся ценные призы:
      </p>}
      <AutoPlaySwipeableViews index={index} onChangeIndex={setIndex}>
        {presents.map(p => (
          <PresentItem key={p.title} {...p} />
        ))}
      </AutoPlaySwipeableViews>
      <Pagination
        index={index} count={presents.length}
        onClick={setIndex}
      />
    </div>
    {false && <p>
      Приглашённым пассажиром считается тот, кто воспользовался
      вашим промокодом в мобильном приложении, оформил билет, и совершил
      поездку по этом билету в течение срока действия акции.
    </p>}
    {customDescription}
  </Section>;
};

const PresentItem = (props: PresentItemType) => {
  const { image, title, description } = props;
  const b = block('present-item');
  return <div className={b()}>
    <div className={b('preview')}>
      <img src={image} alt="title" />
    </div>
    <div className={b('body')}>
      <div className={b('title')}>
        {title}
      </div>
      <div className={b('description')}>
        {description}
      </div>
    </div>
  </div>;
};

export default Presents;
