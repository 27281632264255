// @flow
import React from 'react';
import block from 'bem-cn';
import {
  Presents,
} from '../';
import Rating from '../../modules/Rating';
import Personal from '../../modules/Personal';
import './Promo.scss';

export type PromoPropTypes = {
  vk: vkState,
};


const Promo = (props: PromoPropTypes) => {
  const b = block('promo');
  const { vk } = props;

  return <div className={b()}>
    <div className={b('description')}>
      <h1>
        Приглашай&nbsp;&mdash;
        <br />побеждай!
      </h1>
      <p>
        Пригласите больше всех друзей
        в&nbsp;<strong>Атлас</strong> до&nbsp;30&nbsp;ноября
        и&nbsp;выиграйте телефон Xiaomi и другие призы!
      </p>
    </div>
    {!vk.isRegistered && <Presents />}
    {vk.isRegistered && <Personal />}
    <Rating />
  </div>;
};

export default Promo;
