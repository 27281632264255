// @flow
import React from 'react';

export type HRPropsType = any & {
  height?: number
};

const HR = (props: HRPropsType) => {
  const { height, style, ...otherProps } = props;

  return <div
    {...otherProps}
    style={{
      ...style,
      width: '100%',
      height: height || 1,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    }}
  />;
};

export default HR;
