// @flow
import React, { useState } from 'react';
import {
  Link,
} from '../';
import block from 'bem-cn';
import cross from './cross.svg';
import './Menu.scss';

const items = [
  {
    title: 'Рейтинг участников',
    link: '',
  },
  {
    title: 'Правила конкурса',
    link: 'rules',
  },
];

export type MenuComponentPropTypes = {
  authorized: boolean,
};

const MenuComponent = (props: MenuComponentPropTypes) => {
  const { authorized } = props;
  const b = block('menu');
  const [isOpen, setOpen] = useState(false);
  return <div className={b()}>
    <div className={b('paranga', { open: isOpen })} />
    <div className={b('icon')} onClick={() => setOpen(!isOpen)} />
    <div className={b('content', { open: isOpen })}>
      <button className={b('cross')} onClick={() => setOpen(false)}>
        <img src={cross} />
      </button>
      {[
        ...items,
        authorized ? null : {
          title: 'Принять участие',
          link: 'signup',
        },
      ].filter(Boolean).map(i => (
        <Link
          location={i.link}
          key={i.link}
          className={b('item')}
          onClick={() => setOpen(false)}
        >
          {i.title}
        </Link>
      ))}
    </div>
  </div>;
};

export default MenuComponent;
