// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../reducers/rating';
import { Rating } from '../components';

export type RatingPropTypes = {
  loadRating: (userId?: number) => void,
  participants: Array<ContestParticipant>,
  vk: vkState,
};

class RatingModule extends React.Component<RatingPropTypes> {
  componentDidMount() {
    this.props.loadRating();
  }
  render() {
    const { participants, vk } = this.props;
    return <Rating vk={vk} participants={participants} />;
  };
}

const mapStateToProps = (state: {vk: vkState, rating: ratingState}) => ({
  participants: state.rating.participants,
  vk: state.vk,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  actions, dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(RatingModule);
