// @flow
import React from 'react';
import type { Node, ElementProps } from 'react';
import block from 'bem-cn';
import './Button.scss';

type ButtonProps = ElementProps<any> & {
  children: Node,
  onClick?: () => any,
  size?: 'small' | 'normal' | 'large'
};

const Button = (props: ButtonProps) => {
  const { children, onClick, ...otherProps } = props;
  const b = block('button');
  const size = props.size || 'normal';

  return <div
    {...otherProps}
    onClick={() => onClick ? onClick() : null}
    className={[b({ size }), otherProps.className].filter(Boolean).join(' ')}
  >
    <div className={b('content')}>{children}</div>
  </div>;
};

export default Button;
