// @flow
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import block from 'bem-cn';
import {
  Presents,
  Section,
} from '../../components';
import './Rules.scss';

export type RulesPropTypes = {
  promocode?: string,
};

const Rules = (props: RulesPropTypes) => {
  const b = block('rules');
  const { promocode } = props;
  return <div className={b()}>
    <h1>Правила акции &laquo;Пригласи друга&raquo;</h1>

    <p>
      На&nbsp;этой страничке приведена сокращённая версия правил и условий
      акции &laquo;Пригласи друга&raquo;. Полная версия правил
      доступна <a href="/rules" target="_blank">
        по&nbsp;ссылке
      </a>.
    </p>
    <Section title="Как принять участие?">
      <ol>
        <li>
          Зарегистрируйтесь на сайте
          {' '}<a href="https://atlasbus.ru" target="_blank">atlasbus.ru</a>,{' '}
          <a href="https://atlasbus.by" target="_blank">atlasbus.by</a>
          {' '}или в приложении Атласа, используя номер телефона, привязанный
          к вашему аккаунту ВКонтакте.
        </li>
        <li>
          Зарегистрируйтесь в&nbsp;приложении &laquo;Атлас: пригласи
          друга&raquo; ВКонтакте, используя этот&nbsp;же номер телефона,
          и&nbsp;получите персональный промокод для приглашения.
        </li>
      </ol>
    </Section>
    <Section title="Как победить?">
      <p>
        В&nbsp;конкурсе победят трое участников, пригласивших больше
        всех новых пользователей в&nbsp;Атлас. Приглашение засчитывается если
        ваш друг:
      </p>
      <ul>
        <li>
          Ранее не&nbsp;использовал приложение и&nbsp;сайт Атласа
          для бронирования билетов
        </li>
        <li>
          Активировал ваш промокод {promocode && <strong>{promocode} </strong>}
          в&nbsp;своём мобильном приложении
        </li>
        <li>
          После активации промокода оформил билет в&nbsp;приложении
          и&nbsp;совершил поездку до&nbsp;30&nbsp;ноября.
        </li>
      </ul>
      <p>
        Если у нескольких человек окажется одинаковое количество
        приглашённых друзей — мы определим среди них победителя
        случайным образом
      </p>
    </Section>
    <Presents
      customTitle="Что насчёт подарков?"
      customDescription={<div>
        <p>
          Итоги конкурса мы объявим не позднее конца декабря 2019 года.
          Победителям конкурса достанутся следующие призы:
        </p>
        <ul>
          <li><strong>Смартфон Xiaomi Mi A1, 32 GB</strong> за первое место</li>
          <li>
            <strong>Bluetooth наушники Marshall Major III</strong>
            {' '}за второе место
          </li>
          <li>
            <strong>Аккумулятор Xiaomi Mi Power Bank 3 20000</strong>
            {' '}за третье место
          </li>
        </ul>
        <p>
          Мы свяжемся с победителями через личные сообщения ВКонтакте, чтобы
          уточнить условия доставки подарка. Постарайтесь быть на связи. Если
          в течение 5 дней мы не сможем связаться с вами — нам придётся отдать
          ваш подарок кому-то ещё.
        </p>
      </div>}
    />
  </div>;
};

export default Rules;
